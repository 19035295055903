import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import intro1 from '../assets/images/intro2.png'
import services1 from '../assets/images/services-facepainting.png'
import services2 from '../assets/images/services-henna.png'
import about1 from '../assets/images/about-me-portrait.png'
import about2 from '../assets/images/about-products.png'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Queen Bee Face Painting"/>

        <Header/>

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav}/>

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Creative, temporary transformations!</h2>
                </header>
                <ul className="alt">
                  <li>Birthdays</li>
                  <li>Corporate Events</li>
                  <li>Holidays</li>
                  <li>Fundraisers</li>
                  <li>Private Parties</li>
                </ul>
              </div>
              <span className="image">
                <img src={intro1} alt=""/>
              </span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Painting Services</h2>
            </header>
            <ul className="features">
              <li>
                <span className="image">
                  <img src={services1} alt=""/>
                </span>
                <h3>Face Painting</h3>
                <p className="content">Face Painting includes the design applied to cheek, full or half face, glitter
                  and gems.</p>
                <p className="content">Your event theme or holiday motif will be built into the designs offered, along
                  with fan favorites.
                  All designs can be modified to use different colors than the ones pictured, unless otherwise
                  specified.</p>
                <p className="content">For young ones, I recommend having a great positive first experience over having
                  a great face design,
                  and recommend a hand or knee design if they seem reluctant.</p>
              </li>
              <li>
                <h3>Body Painting</h3>
                <p className="content">Your whole body is a canvas! Contact me for consulation and rates.</p>
                <p className="content"><strong>Belly Painting</strong> - Expecting? What a wonderful time to celebrate
                  your little one's
                  arrival. A beautiful belly paint to match your nursery theme, or a favorite location, or perhaps a
                  friendly stork with a delivery, your baby's name on the tag. Painting can be done in the privacy of
                  your home, or done at a fun baby shower so your guests can be part of the fun!</p>
                <p className="content"><strong>Body Painting</strong> - Have a big event that you need an extra WOW
                  impact? Consider body
                  painting! Neck, shoulders, back, chest, stomach can be designed as well! Comic Con coming? No Problem!
                  Tailgating this weekend? Deck yourself out in your team's colors! Over the top holiday like Mardi
                  Gras, St. Patricks, Day of the Dead? Let me make you unforgettable! Waterproof paints available to
                  give you lasting vibrant colors for your big event!</p>
              </li>
              <li>
                <span className="image">
                  <img src={services2} alt=""/>
                </span>
                <h3>Henna</h3>
                <p className="content">Henna is an ancient artform which uses henna paste applied and dried onto skin.
                  Henna paste is made
                  from crushed henna leaves, lemon juice, essential oils.</p>
                <p className="content">Henna is a temporary tattoo that lasts up to three weeks. It stains the skin in
                  the pattern created
                  with the paste, and exfoliates away over time to remove. The depth of the henna color reaches maximum
                  depth by day three.</p>
                <p className="content">Traditionally, henna is used on the cusp of a big event, such as a wedding.
                  Modern use can be for any
                  reason where the person gets a bit of pampering! Henna needs to dry on the skin two to four hours
                  before it flakes off, so having some time to relax after applying is suggested to get the most out of
                  your henna tattoo.</p>
              </li>
            </ul>
          </section>

          <section id="second" className="main">
            <header className="major">
              <h2>About Me</h2>
            </header>
            <span className="image">
                  <img src={about1} alt=""/>
                </span>
            <p>
              I have always had a passion for art. As a stay at home mom, my first priority needed to be my family, but
              I wanted to find something that would use my creative side. I discovered face painting and was hooked!</p>
            <p>Face painting is so much more than a design. It releases the imagination. Kids jump
              right into pretend play, teens enjoy their reflection and adults feel young at heart when the participate
              in the experience.
            </p>
            <h3>Products Used</h3>
            <span className="image">
                  <img src={about2} alt=""/>
                </span>
            <p>All paints used are professional grade quality face and body paints. Each paint has
              met the following requirements:</p>
            <ul>
              <li>Hypo-allergenic</li>
              <li>Non-toxic</li>
              <li>Water Soluable (unless it is waterproof paint)</li>
              <li>FDA Complaint</li>
            </ul>
            <p>As with all cosmetics, there is a slight chance for allergic reaction, please feel
              free to contact me with any questions regarding allergies.</p>
            <p>Henna used is all natural, used with fresh lemon juice and essential oils.</p>
            <p>Glitter and adhesives are cosmetic grade, so they will not irritate skin. Glitter
              will not scratch retina if it accidentally gets in eyes.</p>
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>Let's Talk!</h2>
              <h4>Queen Bee Face Painting and Wearable Art</h4>
              <h4>(651) 398-3562</h4>
              <h4>
                <a href="mailto:queenbeefacepainting@gmail.com">queenbeefacepainting@gmail.com</a>
              </h4>
            </header>
            <h4>Cash, Check, Credit Card, Paypal or Venmo accepted.</h4>
            <h4>All bookings subject to deposit and contract.</h4>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
