import React from 'react'

import logo from '../assets/images/queen_bee_logo.png';

const Header = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="Queen Bee Face Painting" /></span>
    </header>
)

export default Header
