import { Link } from 'gatsby'
import React from 'react'

const Footer = props => (
  <footer id="footer">
    <section>
      <ul className="icons">
        <li>
          <a href="https://www.facebook.com/queenbeefacepainting/" className="icon fa-facebook alt">
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/queen_bee_face_painting/" className="icon fa-instagram alt">
            <span className="label">Instagram</span>
          </a>
        </li>
      </ul>
    </section>
    <p className="copyright">
      &copy; Queen Bee Face Painting and Wearable Art
    </p>
  </footer>
)

export default Footer
